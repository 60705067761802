.panel {
  &.eraser {
    position: absolute;
    right: 0;
    top: 0;
    width: 20rem;
    height: 30rem;
    background: lightgrey;

    display: grid;
    grid-template-rows: 2rem 1fr 2rem;

    &.minimised {
      height: 2rem;
    }

    & > .inner {
      padding: 1rem;
    }
  }
}
