.panel {
  z-index: 1;

  .panel-title {
    background: rgba(0, 0, 0, 0.1);
    padding: 0.25em 0.5em;
  }

  &.minimised {
    .panel-title {
      background: unset;
    }
  }

  &.passthrough {
    pointer-events: none;
  }
}
