.panel {
  &.layers {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20rem;
    max-height: 20rem;
    background: lightgrey;

    display: grid;
    grid-template-rows: 2rem 1fr 2rem;

    &.minimised {
      height: 2rem;
    }

    & .layers-toolbar {
    }

    .layers-list {
      overflow-x: auto;

      .layer {
        padding: 0.5rem 1rem;

        &.selected {
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
