.board-container {
  height: 100%;
  width: 100%;
}

.board-loading {
  background: white;

  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;

  .loading-message {
    width: 100%;
    text-align: center;
    font-weight: bolder;
    font-size: 16pt;
  }
}
