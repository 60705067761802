.panel {
  &.brush {
    position: absolute;
    right: 0;
    top: 0;
    width: 20rem;
    background: lightgrey;

    display: grid;
    grid-template-rows: 2rem 1fr 2rem;

    &.minimised {
      height: 2rem;
    }

    & > .inner {
      padding: 1rem;
      .colour-history {
        display: grid;
        margin: 1rem 0;

        grid-template-rows: repeat(2, 50px);
        grid-template-columns: repeat(5, 50px);
      }
    }
  }
}
