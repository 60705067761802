.tools-panel {
  width: 4rem;
  height: 100vh;
  background: lightgrey;

  & > .tool {
    display: flex;
    width: 100%;
    height: 50px;

    align-items: center;

    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    span {
      width: 100%;
      text-align: center;
    }

    &.selected {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
